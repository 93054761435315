import React from "react";
import styles from "./style.module.scss";

export const Social = () => {
  return (
    <div className={styles.container}>
      <a href={"tel:0542454073"} target={"_blank"}>
        <img src={"/assets/images/phone.svg"} />
      </a>
      <a href={"https://www.instagram.com/tomershefer"} target={"_blank"}>
        <img src={"/assets/images/instagram.svg"} />
      </a>
      <a href={"https://www.facebook.com/tomershefer"} target={"_blank"}>
        <img src={"/assets/images/facebook.svg"} />
      </a>
    </div>
  );
};
