import React from "react";
import styles from "./style.module.scss";
import {Social} from "../../components/social/Social";
import {ContactForm} from "../../components/contact-form/ContactForm";

const GALLERY_IMAGES = [
  "/assets/images/home-gallery/1.jpg",
  "/assets/images/home-gallery/2.jpg",
  "/assets/images/home-gallery/3.jpg",
  "/assets/images/home-gallery/4.jpg",
  "/assets/images/home-gallery/5.jpg",
  "/assets/images/home-gallery/6.jpg",
  "/assets/images/home-gallery/7.jpg",
  "/assets/images/home-gallery/8.jpg",
].reverse();

export const Home = () => {
  const aboutMeRef = React.useRef<HTMLDivElement>(null);

  const scrollToAboutMe = () => {
    aboutMeRef.current?.scrollIntoView({behavior: "smooth"});
  };

  return (
    <div>
      <div className={styles.sectionContainer}>
        <div
          className={`${styles.section} ${styles.heroSection} ${styles.fullScreen}`}
          style={{
            backgroundImage: "url(/assets/images/IMG_4729.jpg)",
          }}>
          <div className={styles.socialList}>
            <Social />
          </div>
          <h1 role={"heading"}>
            <a href={"/"}>
              <img
                className={styles.logo}
                src={"/assets/images/Shefer_Logo_-_White_NO_BG.PNG"}
              />
            </a>
          </h1>
          <div onClick={scrollToAboutMe} className={styles.arrowDown}>
            <img src={"/assets/images/arrow-down.svg"} width={56} />
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div
          ref={aboutMeRef}
          className={`${styles.section} ${styles.fullScreen}`}
          style={{
            backgroundImage:
              "url(/assets/images/0Y4A9278_1cac234a-67cb-4059-9810-8a4f4ec95d0e.jpg)",
          }}>
          <div>
            <p className={styles.aboutMeTitle}>קצת עליי</p>
            <p className={styles.aboutMeText}>
              אני לא הולך לרשום כאן עוד משהו בנאלי או יומרני.
              <br />
              האנרגיה, החיוך והשמחה הם הבסיס שאותו אני מתחייב להביא.
              <br />
              קריאת הקהל היא הדבר החשוב ביותר שהופך אותי לנכון עבורכם!
              <br />
              וכמה שאומרים שקשה לרצות את כולם, תשאירו את זה לי בבקשה.
              <br />
              לכם רק נותר לספר לי איך נראה האירוע המושלם מנקודת המבט שלכם וביחד
              נוציא אותו אל הפועל.
              <br />
              <br />
              שלכם,
              <br />
              תומר.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.videoSection}>
          <iframe
            width={"100%"}
            height={"100%"}
            src={
              "https://www.youtube.com/embed/B7nbRTTuLB0?rel=0&controls=0&showinfo=0"
            }
            frameBorder={0}
            allowFullScreen={true}
          />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div
          className={styles.section}
          style={{
            backgroundImage: "url(/assets/images/CS8A5494.webp)",
          }}>
          <div>
            <p className={styles.testimonialsTitle}>הזוגות מספרים עליי</p>
            <div className={styles.mit4mit_wed_reviews_Container}>
              <a href={"https://www.mit4mit.co.il/biz/98648"} target="_blank">
                <img src={"/assets/images/mit4mit_white.avif"} />
              </a>
              <a
                href={"https://www.wedreviews.co.il/provider/stories/262288"}
                target="_blank">
                <img src={"/assets/images/wed_reviews.avif"} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={`${styles.gallery}`}>
          {GALLERY_IMAGES.map(img => (
            <div key={img} className={styles.galleryImageWrapper}>
              <img src={img} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.videoSection}>
          <iframe
            width={"100%"}
            height={"100%"}
            src={
              "https://www.youtube.com/embed/bN-nq8pUNJM?rel=0&controls=0&showinfo=0"
            }
            frameBorder={0}
            allowFullScreen={true}
          />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div
          className={styles.section}
          style={{
            backgroundImage: "url(/assets/images/Enlight300.jpg)",
          }}>
          <iframe
            width="100%"
            height="450"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1258287526&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true"
          />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div
          className={styles.section}
          style={{
            backgroundImage: "url(/assets/images/contact-me.jpg)",
          }}>
          <ContactForm />
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <div className={styles.videoSection}>
          <iframe
            width={"100%"}
            height={"100%"}
            src={
              "https://www.youtube.com/embed/svO9wVpiuPE?rel=0&controls=0&showinfo=0"
            }
            frameBorder={0}
            allowFullScreen={true}
          />
        </div>
      </div>
    </div>
  );
};
