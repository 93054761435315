import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {
  callRequest,
  NetworkCallerMethod,
} from "../../classes/network/network-caller";
import ApiEndpoints from "../../classes/network/api-endpoints";

export const ContactForm = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSentSuccessfully, setIsSentSuccessfully] = useState(false);

  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    setErrorMessage("");
  }, [title, email, phoneNumber, body]);

  const sendForm = () => {
    if (title.length == 0) {
      setErrorMessage('שדה "שם הזוג" הינו שדה חובה');
      return;
    }
    if (phoneNumber.length == 0) {
      setErrorMessage('שדה "מספר טלפון" הינו שדה חובה');
      return;
    }
    setIsSending(true);
    callRequest({
      method: NetworkCallerMethod.POST,
      api: ApiEndpoints.CONTACT_FORM,
      data: {
        title,
        email,
        phone_number: phoneNumber,
        body,
      },
    }).then(() => {
      clearForm();
      setIsSending(false);
      setIsSentSuccessfully(true);
    });
  };

  const clearForm = () => {
    setTitle("");
    setEmail("");
    setPhoneNumber("");
    setBody("");
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>דברו איתי</p>
      <div>
        <p>
          טלפון:{" "}
          <a href="tel:054-2454073" title="tel:054-2454073">
            054-2454073
          </a>
        </p>
        <p>
          מייל:{" "}
          <a
            href="mailto:djtomershefer@gmail.com"
            title="mailto:djtomershefer@gmail.com">
            djtomershefer@gmail.com
          </a>
        </p>
        <p>כתובת: רבנו חננאל 24, תל אביב - יפו</p>
        <p>ספרו לי איך אתם מדמיינים את האירוע הכי שמח בחייכם!</p>
      </div>
      <form>
        <div>
          <input
            value={title}
            onChange={event => setTitle(event.target.value)}
            type="text"
            placeholder="שם הזוג"
          />
        </div>
        <div>
          <input
            value={email}
            onChange={event => setEmail(event.target.value)}
            type="email"
            placeholder="דואר אלקטרוני"
          />
        </div>
        <div>
          <input
            value={phoneNumber}
            onChange={event => setPhoneNumber(event.target.value)}
            type="tel"
            placeholder="מספר טלפון"
          />
        </div>
        <div>
          <textarea
            value={body}
            onChange={event => setBody(event.target.value)}
            placeholder="דברו אליי :)"
          />
        </div>
        <div>
          <button
            onClick={sendForm}
            className={`button ${isSending && "isLoading"}`}
            type={"button"}>
            שלח/י
          </button>
          {isSentSuccessfully && (
            <div>
              <p>הטופס נשלח בהצלחה!</p>
            </div>
          )}
          {errorMessage && (
            <div>
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
