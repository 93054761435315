import React from "react";
import "./App.scss";
import {Routes, Route, Navigate} from "react-router-dom";
import {Home} from "./screens/home/Home";
import {Footer} from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<Home />} index={true} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
