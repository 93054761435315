import React from "react";
import styles from "./style.module.scss";
import {Social} from "../social/Social";

export const Footer = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src={"/assets/images/Shefer_Logo_-_White_NO_BG.PNG"}
      />
      <div>
        <Social />
      </div>
      <p className={styles.copyright}>
        © {new Date().getFullYear()}{" "}
        <a href="/" title="">
          DJ Tomer Shefer - די ג'יי תומר שפר
        </a>
      </p>
    </div>
  );
};
